<template>
<div>
  <Row>
    <Col span="24">
      <Form :label-width="100" ref="AddForm">
        <FormItem label="角色名称" prop="role_name">
          <Input v-model="formItem.role_name" style="width: 600px"></Input>
        </FormItem>

        <FormItem label="角色简介" prop="role_intro">
          <Input type="textarea" v-model="formItem.role_intro" style="width: 600px"></Input>
        </FormItem>

        <FormItem label="上级角色" prop="exam_duration">
          <treeselect v-model="formItem.role_parent" :options="roles" style="width: 600px"/>
        </FormItem>

        <FormItem>
          <Button size="large" type="primary" @click="submit()" :disabled="btnDisabled">确定</Button>
        </FormItem>

      </Form>
    </Col>
  </Row>
</div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import api from "../../api/setup";
export default {
  name: "role",
  components: { Treeselect },
  data: function(){
    return {
      formItem: {

      },
      rid: '',
      roles: [
        {id: 0,label: '顶级',}
      ]
    }
  },
  computed: {
    btnDisabled: function(){
      return this.formItem.role_name === '' || typeof this.formItem.role_parent === 'undefined'
    }
  },
  methods: {
    each: function(arr){
      arr.forEach((item, index) => {
        if(item.id === parseInt(this.rid)){
          arr[index]['isDisabled'] = true;
          return false;
        }
        if(item.children && item.children.length > 0){
          this.each(item.children)
        }
      })
    },
    submit: function(){
      this.$http.put(api.modRole, this.formItem).then(response => {
        if(response.data.success) {
          this.$Message.success('修改成功')
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    }
  },
  mounted() {
    this.$emit('lastPage', '/setup/roles');
    this.rid = this.$route.query.id;
    this.$http.get(api.getRoles).then(response => {
      if(response.data.success) {
        this.each(response.data.res);
        this.roles.push(...response.data.res);
        this.$http.get(api.getRole, {params: {id: this.rid}}).then(res => {
          if(res.data.success) {
            this.formItem = res.data.res;
          } else {
            this.$Message.error(res.data.err);
            console.log(res)
          }
        })
      } else {
        this.$Message.error(response.data.err);
        console.log(response)
      }
    })
  },
  destroyed() {
    this.$emit('lastPage', '')
  }
}
</script>

<style scoped>

</style>